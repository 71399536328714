.container {
    margin: 1em auto;
    max-width: 600px;
}
blockquote {
    margin: 1em 0;
}
.jumbotron {
    margin: 2em auto;
    max-width: 400px;
}
.jumbotron h2 {
    margin-top: 0;
}
.jumbotron .help-block {
    font-size: 14px;
}